import { toast } from 'App'

export const currencyFormat = (number?: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(number || 0)
}

export const dateFormat = (date: number) =>
  new Intl.DateTimeFormat('en-CA').format(date)

export function calculateAge(date: number) {
  const now = new Date().getTime() - 1000 * 60 * 60 * 24 * 4
  const diff = now - date
  const age = diff / (1000 * 60 * 60 * 24 * 365)
  return age >= 18
}

export const showToast = (type: string, title: string, description: string) => {
  toast({
    status: type == 'success' ? 'success' : 'error',
    title: title,
    description: description,
    position: 'top',
    duration: 2000,
    isClosable: true,
  })
}

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

export const scrollElementIntoView = (
  element: HTMLElement,
  behavior?: ScrollBehavior,
) => {
  const scrollTop = window.pageYOffset || element.scrollTop

  // Furthermore, if you have for example a header outside the iframe
  // you need to factor in its dimensions when calculating the position to scroll to
  //  const headerOutsideIframe = window.parent.document.getElementsByClassName('myHeader')[0].clientHeight

  const finalOffset = element.getBoundingClientRect().top + scrollTop

  window.parent.scrollTo({
    top: finalOffset,
    behavior: behavior || 'auto',
  })
}
