import { useQueryClient } from '@tanstack/react-query'
import { EarnXContext } from 'App'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useGetUserData, useSetOwner } from 'utils/apis/user.api'
import { connectAccount, disconnectWallet } from 'utils/bc-helper'
import { showToast } from 'utils/helper'
import { useIsUserLogIn } from 'utils/hooks'
import ForgotPassword from 'views/forgotPassword'
import Landing from 'views/landing'
import Login from 'views/login'
import MarketPlace from 'views/marketPlace'
import SignUp from 'views/signup'
import Transactions from 'views/transactions'
import UserProfile from 'views/userProfile'
import PrivateRoute from './privateRoute'

const routes = [
  {
    path: '/',
    Component: Landing,
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/signup',
    Component: SignUp,
  },
  {
    path: '/forgot-password',
    Component: ForgotPassword,
  },
  {
    path: '/market',
    Component: MarketPlace,
  },
  {
    path: '/profile',
    Component: UserProfile,
    isPrivate: true,
  },
  {
    path: '/transactions',
    Component: Transactions,
    isPrivate: true,
  },
  {
    path: '/verify-email',
    Component: ForgotPassword,
  },
]

export default function AppRoutes() {
  const client = useQueryClient()

  const isLogin = useIsUserLogIn()
  const { walletAddress, setWalletAddress } = React.useContext(EarnXContext)

  const { data: userProfileData } = useGetUserData()
  const { mutate: setOwner } = useSetOwner()

  React.useEffect(() => {
    if (isLogin) {
      if (!walletAddress) {
        connectAccount(client).then(wallet => {
          if (!userProfileData?.ownerAddress) {
            setOwner(wallet || '')
          } else if (
            userProfileData?.ownerAddress?.toLowerCase() !==
            wallet?.toLowerCase()
          ) {
            showToast(
              'error',
              'Something went wrong',
              `Please connect to ${userProfileData?.ownerAddress}`,
            )
            disconnectWallet()
            return
          }
          setWalletAddress(
            wallet || '0x0000000000000000000000000000000000000000',
          )
        })
      }
    }
  }, [isLogin])
  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          element={
            isPrivate ? (
              <PrivateRoute component={<Component />} />
            ) : (
              <Component />
            )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
