import React from 'react'
import {
  Box,
  Container,
  TableContainer,
  Heading,
  Flex,
  Text,
  Center,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import Footer from 'components/footer'
import Header from 'components/header'
import UserBg from 'assets/images/bg-2.png'
import UserInfoBanner from 'components/userInfoBanner'
import DataTable from 'components/table'
import { createColumnHelper } from '@tanstack/react-table'
import { FromIcon, ToIcon } from 'assets/icons'
import { useGetTransaction } from 'utils/apis/nft.api'
import { TransactionType } from 'utils/types/nft.type'
import { Link } from 'react-router-dom'
export default function Transactions() {
  const {
    data: transactions,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useGetTransaction()

  const allData: TransactionType[] | undefined = []
  transactions?.pages?.map(page => allData?.push(...page))

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <Box
        bg={`url(${UserBg}) no-repeat top right`}
        bgSize="cover"
        pb="60px"
        position="relative"
        overflow="hidden"
      >
        <Header />
        <Container maxW="1280px" mt="60px" position="relative" zIndex="1">
          <UserInfoBanner />
          <Button
            as={Link}
            to="/profile"
            mb="30px"
            size="lg"
            variant="secondary"
          >
            Go Back
          </Button>
          <TableContainer bg="bgGradient" rounded="10px" p="30px">
            <Heading as="h2" mb="45px" fontSize={30}>
              Transaction History
            </Heading>
            {!transactions?.pages?.length || !transactions?.pages[0]?.length ? (
              <Text size="16px">
                {isLoading ? 'Loading...' : 'No Transactions'}
              </Text>
            ) : (
              <DataTable
                columns={isLargerThan768 ? columns : mobileColumns}
                data={allData || []}
              />
            )}
          </TableContainer>
          {transactions?.pages[transactions?.pages?.length - 1]?.length ===
            2 && (
            <Center mt="25px">
              <Button isLoading={isFetching} onClick={() => fetchNextPage()}>
                Load More
              </Button>
            </Center>
          )}
        </Container>
        <Box
          position="absolute"
          bottom={10}
          right={0}
          transform="translate(50%, -100%)"
          bg="linear-gradient(104.03deg, #FE81AB 0%, #FDAA94 99.86%)"
          opacity="0.9"
          filter="blur(150px)"
          width={48}
          height={48}
        />
      </Box>
      <Footer />
    </>
  )
}

export const columnHelper = createColumnHelper<TransactionType>()
export const columns = [
  columnHelper.accessor('userAddress', {
    cell: info => (
      <Flex gap="10px">
        {info.getValue() === 'to' ? <ToIcon /> : <FromIcon />}{' '}
        <Box>
          <Text fontSize="14px" variant="primary">
            {info.getValue() === 'to' ? 'To' : 'From'}
          </Text>

          <Text
            as="a"
            href={info?.row?.original?.addressUrl}
            target="_blank"
            rel="noreferrer"
          >
            {info.getValue()?.replace(info.getValue()?.slice(5, 37), '****')}
          </Text>
        </Box>
      </Flex>
    ),
    header: 'Address',
  }),

  columnHelper.accessor('createdAt', {
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    header: 'Date',
  }),
  columnHelper.accessor('amount', {
    cell: info => `$${info.getValue()}`,
    header: 'Amount',
  }),
  columnHelper.accessor('type', {
    cell: info => info.getValue(),
    header: 'Transfer Type',
  }),
  columnHelper.accessor('trxHash', {
    cell: info => (
      <a href={info?.row?.original?.trxUrl} target="_blank" rel="noreferrer">
        {info.getValue()?.replace(info.getValue()?.slice(5, -5), '....')}
      </a>
    ),
    header: 'Transaction hash',
  }),
]

export const mobileColumns = [
  columnHelper.accessor('amount', {
    cell: info => `$${info.getValue()}`,
    header: 'Amount',
  }),
  columnHelper.accessor('type', {
    cell: info => info.getValue(),
    header: 'Transfer Type',
  }),
  columnHelper.accessor('trxHash', {
    cell: info => (
      <a href={info?.row?.original?.trxUrl} target="_blank" rel="noreferrer">
        {info.getValue()?.replace(info.getValue()?.slice(5, -5), '....')}
      </a>
    ),
    header: 'Transaction hash',
  }),
]
