import React, { useState } from 'react'
import {
  Grid,
  Heading,
  Box,
  Input,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Icon,
  Flex,
  Checkbox,
} from '@chakra-ui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { BiHide, BiShow } from 'react-icons/bi'
import { AuthFormValues } from 'utils/types/user.type'
import { Link, NavLink, useSearchParams } from 'react-router-dom'
import EarnXCard from 'components/earnXCard'
import AuthBg from 'assets/images/auth-bg.jpg'
import {
  InstagramIcon,
  LogoIcon,
  TwitterIcon,
  FacebookIcon,
} from 'assets/icons'
import NFTImage from 'assets/images/nft-img.png'

interface Props {
  title: string
  isLogin?: boolean
  isRegister?: boolean
  isLoading: boolean
  onSubmitData: (data: AuthFormValues) => void
}

export default function UserAuth({
  title,
  isRegister,
  isLoading,
  onSubmitData,
}: Props) {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      bg={`url(${AuthBg}) no-repeat center`}
      bgSize="cover"
      py="100px"
      position="relative"
    >
      <Grid justifyItems="center">
        <Box
          position="absolute"
          top="30px"
          left={{ base: '50%', md: '30px' }}
          transform={{ base: 'translate(-50%, 0)', md: 'none' }}
        >
          <NavLink to="/">
            <LogoIcon />
          </NavLink>
        </Box>
        <Box display={{ base: 'none', md: 'block' }}>
          <EarnXCard image={NFTImage} />
        </Box>
        <Flex
          position="absolute"
          bottom="30px"
          left={{ base: '50%', md: '30px' }}
          transform={{ base: 'translate(-50%, 0)', md: 'none' }}
          gap="30px"
        >
          <FacebookIcon /> <TwitterIcon /> <InstagramIcon />
        </Flex>
      </Grid>
      <Box px="15px">
        <Heading fontSize={{ base: '34px', md: '55px' }}>{title}</Heading>
        {!isRegister && (
          <Text mt="15px">Sign in to start managing your NFTs!</Text>
        )}
        <HookForm
          isLoading={isLoading}
          isRegister={isRegister}
          onSubmitData={onSubmitData}
        />
      </Box>
    </Grid>
  )
}

function HookForm({
  isLoading,
  isRegister,
  onSubmitData,
}: {
  isLoading: boolean
  isRegister?: boolean
  onSubmitData: (data: AuthFormValues) => void
}) {
  const [searchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const referral = searchParams.get('ref')
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<AuthFormValues>({
    defaultValues: {
      fullname: '',
      email: '',
      password: '',
      referallAddress: referral || '',
    },
  })

  const isAgree = watch('terms')

  const onSubmit: SubmitHandler<AuthFormValues> = values => {
    return new Promise(resolve => {
      resolve(onSubmitData(values))
    })
  }

  return (
    <Box
      mt="30px"
      w="100%"
      maxW="520px"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isRegister && (
        <FormControl isInvalid={Boolean(errors.fullname)} mb="25px">
          <FormLabel htmlFor="name">Full Name</FormLabel>
          <Input
            size="lg"
            _autofill={{ bg: 'none' }}
            focusBorderColor="primary.500"
            placeholder="John"
            {...register('fullname', {
              required: 'Name is required',
            })}
            variant="primary"
          />
          <FormErrorMessage>
            {errors.fullname && errors.fullname.message}
          </FormErrorMessage>
        </FormControl>
      )}
      <FormControl isInvalid={Boolean(errors.email)} mb="25px">
        <FormLabel htmlFor="name">Email</FormLabel>
        <Input
          size="lg"
          _autofill={{ bg: 'none' }}
          focusBorderColor="primary.500"
          placeholder="john@mail.com"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Enter valid email.',
            },
          })}
          variant="primary"
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.password)} mb="25px">
        <FormLabel htmlFor="password">Password</FormLabel>
        <InputGroup size="md">
          <Input
            size="lg"
            variant="primary"
            focusBorderColor="primary.500"
            {...register('password', {
              required: 'Password is required',
              validate: isRegister
                ? {
                    isCharacter: value =>
                      // eslint-disable-next-line
                      /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ||
                      'Must contain one Special character',
                    // eslint-disable-next-line
                    isSmall: value =>
                      /[a-z]/.test(value) || 'Must contain one Small letter',
                    isCapital: value =>
                      /[A-Z]/.test(value) ||
                      'Must contain one Capital character',
                    isDigit: value =>
                      /\d/.test(value) || 'Must contain one Digit character',
                  }
                : {},
              minLength: {
                value: 8,
                message: isRegister
                  ? 'Minimum length should be 8'
                  : 'Incorrect password',
              },
            })}
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            autoComplete="new-password"
          />
          <InputRightElement
            // eslint-disable-next-line react/no-children-prop
            children={
              <Icon
                as={show ? BiShow : BiHide}
                color="gray.400"
                onClick={handleClick}
              />
            }
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
      {isRegister ? (
        <>
          <FormControl isInvalid={Boolean(errors.referallAddress)} mb="25px">
            <FormLabel htmlFor="password">Referral code (Optional)</FormLabel>
            <Input
              size="lg"
              variant="primary"
              focusBorderColor="primary.500"
              {...register('referallAddress')}
              placeholder="QXYWZ"
            />
            <FormErrorMessage>
              {errors.referallAddress && errors.referallAddress.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.terms)} mb="25px">
            <Checkbox
              {...register('terms', {
                required: 'Agree to our terms & conditions before register.',
              })}
              isChecked={isAgree}
            >
              I have read and agree to the{' '}
              <Text
                variant="pink"
                as="a"
                target="_blank"
                href="https://sites.google.com/view/earnx-privacy-policy"
              >
                terms & conditions and privacy policy
              </Text>
            </Checkbox>
            <FormErrorMessage>
              {errors.terms && errors.terms.message}
            </FormErrorMessage>
          </FormControl>
        </>
      ) : (
        <Flex justifyContent="flex-end">
          <Text variant="pink" to="/forgot-password" as={Link}>
            Forgot password?
          </Text>
        </Flex>
      )}
      <Button
        fontWeight="400"
        w="100%"
        my="35px"
        variant="primary"
        isLoading={isLoading}
        type="submit"
        size="lg"
      >
        {isRegister ? 'Sign Up' : 'Sign In'}
      </Button>
      <Text color="muted" textAlign="center" className="fs--14 fw--400">
        {isRegister ? 'Already have account?' : 'Don’t have account?'}{' '}
        <Text
          to={isRegister ? '/login' : '/signup'}
          as={Link}
          variant="pink"
          className="fs--14 fw--400"
        >
          {isRegister ? 'Sign In' : 'Sign Up'}
        </Text>
      </Text>
    </Box>
  )
}
