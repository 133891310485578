import UserAuth from 'components/userAuth'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSignup } from 'utils/apis/user.api'

export default function SignUp() {
  const navigate = useNavigate()

  const { mutate: signup, isLoading, data: response } = useSignup()

  useEffect(() => {
    if (response?.user)
      navigate('/verify-email', { state: { email: response?.user?.email } })
  }, [response])
  return (
    <div>
      <UserAuth
        title="Sign Up"
        isLoading={isLoading}
        onSubmitData={data => signup(data)}
        isRegister
      />
    </div>
  )
}
