import { useQueryClient } from '@tanstack/react-query'
import { connectAccount } from 'utils/bc-helper'

export function useIsUserLogIn() {
  return localStorage.getItem('token') ? true : false
}

export function useWalletConnected(walletAddress: string) {
  return walletAddress ? true : false
}

export function useConnectWallet() {
  const client = useQueryClient()
  const connectWallet = connectAccount(client)
  return {
    connectWallet,
  }
}
