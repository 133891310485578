import React, { useEffect } from 'react'
import UserAuth from 'components/userAuth'
import { useLogin } from 'utils/apis/user.api'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const navigate = useNavigate()
  const { mutate: login, isLoading, data: response } = useLogin()

  useEffect(() => {
    if (response?.token) {
      localStorage.setItem(
        'token',
        response?.token?.access_token?.split(' ')?.[1],
      )
      navigate('/profile')
    }
  }, [response])
  return (
    <div>
      <UserAuth
        title="Sign In"
        isLoading={isLoading}
        onSubmitData={data => login(data)}
      />
    </div>
  )
}
