import React, { useContext } from 'react'
import {
  Box,
  Flex,
  Container,
  Button,
  // ButtonGroup,
  Center,
  Text,
} from '@chakra-ui/react'
import Footer from 'components/footer'
import Header from 'components/header'
import MarketCard from 'components/marketCard'
import UserBg from 'assets/images/bg-2.png'
// import { BiChevronDown } from 'react-icons/bi'
import UserInfoBanner from 'components/userInfoBanner'
import { connectAccount, disconnectWallet } from 'utils/bc-helper'
import { useQueryClient } from '@tanstack/react-query'
import { EarnXContext } from 'App'
import {
  useGetUserData,
  // useGetUserProfileStats,
  useSetOwner,
} from 'utils/apis/user.api'
import { useOwnerNft } from 'utils/apis/nft.api'
import { NftType } from 'utils/types/nft.type'
import { showToast } from 'utils/helper'
// import { KEYS } from 'keys/keys'

export default function UserProfile() {
  const client = useQueryClient()

  // const walletAddress = client.getQueryData([KEYS.WALLET_ADDRESS],{stale:false  })
  const { walletAddress, setWalletAddress } = useContext(EarnXContext)
  const { data: userProfileData } = useGetUserData()
  const { data: userNfts, isFetching, fetchNextPage } = useOwnerNft(10)

  // const { data: ownerStats } = useGetUserProfileStats()

  const { mutate: setOwner } = useSetOwner()

  return (
    <>
      <Box
        bg={`url(${UserBg}) no-repeat top right`}
        bgSize="cover"
        pb="60px"
        position="relative"
        overflow="hidden"
      >
        <Header />
        <Container position="relative" maxW="1280px" mt="60px">
          {!walletAddress && (
            <Center
              bg="blackAlpha.100"
              position="absolute"
              top="0"
              w="100%"
              h="100%"
              zIndex="2"
            >
              <Button
                variant="primary"
                w="200px"
                size="lg"
                onClick={async () => {
                  if (!walletAddress) {
                    const wallet = await connectAccount(client)

                    if (!userProfileData?.ownerAddress) {
                      setOwner(wallet || '')
                    } else if (
                      userProfileData?.ownerAddress?.toLowerCase() !==
                      wallet?.toLowerCase()
                    ) {
                      showToast(
                        'error',
                        'Something went wrong',
                        `Please connect to ${userProfileData?.ownerAddress}`,
                      )
                      await disconnectWallet()
                      return
                    }
                    setWalletAddress(
                      wallet || '0x0000000000000000000000000000000000000000',
                    )
                  }
                }}
              >
                {walletAddress
                  ? walletAddress?.replace(walletAddress?.slice(5, 37), '****')
                  : 'Connect Wallet'}
              </Button>
            </Center>
          )}
          <UserInfoBanner />
          {/* <p>
            <>Balance is {ownerStats?.usdtBalance?.toFixed(2) || 0}</>
          </p> */}
          {/* <ButtonGroup
            spacing="20px"
            mb="25px"
            filter={walletAddress ? '' : 'blur(8px)'}
          >
            <Button rightIcon={<BiChevronDown />}>Price</Button>
            <Button variant="secondary">Listed NFT</Button>
            <Button variant="secondary" rightIcon={<BiChevronDown />}>
              Sort By
            </Button>
          </ButtonGroup> */}
          <Flex
            wrap="wrap"
            gap="35px"
            filter={walletAddress ? '' : 'blur(8px)'}
            justifyContent="center"
          >
            {(!userNfts?.pages?.length || !userNfts?.pages[0]?.length) && (
              <Text size="16px">No NFT Owned</Text>
            )}

            {userNfts?.pages?.map((page, index) =>
              page?.map((item: NftType) => (
                <MarketCard key={index} nftData={item} isOwnedNft={true} />
              )),
            )}
          </Flex>
          {userNfts?.pages[userNfts?.pages?.length - 1]?.length === 2 && (
            <Center mt="25px">
              <Button isLoading={isFetching} onClick={() => fetchNextPage()}>
                Load More
              </Button>
            </Center>
          )}
        </Container>
        <Box
          position="absolute"
          bottom={10}
          right={0}
          transform="translate(50%, -100%)"
          bg="linear-gradient(104.03deg, #FE81AB 0%, #FDAA94 99.86%)"
          opacity="0.9"
          filter="blur(150px)"
          width={48}
          height={48}
        />
      </Box>
      <Footer />
    </>
  )
}
