import React, { useContext } from 'react'
import {
  Box,
  Flex,
  Button,
  HStack,
  StackDivider,
  Text,
  Image,
  Heading,
  InputGroup,
  InputRightAddon,
  Input,
  Center,
} from '@chakra-ui/react'
import pattern from 'assets/images/pattern.png'
import CardBg from 'assets/images/card-bg.png'
import busd from 'assets/images/busd.png'
import usdt from 'assets/images/usdt.png'
import { BiChevronDown, BiCopy } from 'react-icons/bi'
import { GrHistory } from 'react-icons/gr'
import { CopyIcon } from 'assets/icons'
import { Link } from 'react-router-dom'
import { useGetUserData, useGetUserProfileStats } from 'utils/apis/user.api'
import { showToast } from 'utils/helper'
import { useClaimReferral } from 'utils/apis/nft.api'
import { EarnXContext } from 'App'

export default function UserInfoBanner() {
  const { data: userProfileData } = useGetUserData()

  const { data: ownerStats } = useGetUserProfileStats()

  const { mutate: claimReferral, isLoading } = useClaimReferral()
  const { walletAddress } = useContext(EarnXContext)

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      gap="30px"
      mb="90px"
      filter={walletAddress ? '' : 'blur(8px)'}
      position="relative"
      zIndex="1"
    >
      <Box
        bg="var(--chakra-colors-bgGradient) padding-box, var(--chakra-colors-primaryGradient) border-box"
        border="1px solid transparent"
        shadow="0px 4px 20px rgba(43, 45, 51, 0.16)"
        p={{ base: '30px 15px', sm: '45px 35px' }}
        rounded="10px"
        position="relative"
        flex="1"
      >
        <Flex
          justifyContent="space-between"
          mb="30px"
          // direction={{ base: 'column', sm: 'row'}}
          fontSize={{ base: '12px', sm: '16px' }}
        >
          <Box>
            <Text>Current Wallet</Text>
            <Button
              variant="link"
              leftIcon={<CopyIcon />}
              rightIcon={<BiChevronDown />}
            >
              {userProfileData?.ownerAddress?.replace(
                userProfileData?.ownerAddress?.slice(5, 37),
                '...',
              ) || '0x000...000000'}
            </Button>
          </Box>
          <Box>
            <Text>Available balance</Text>
            <Flex gap="8px">
              <Flex as="span" gap="5px" align="center">
                <Image w="20px" src={busd} />
                {ownerStats?.balance?.toFixed(2) || 0}
              </Flex>
              |
              <Flex as="span" gap="5px" align="center">
                <Image w="20px" src={usdt} />
                {ownerStats?.usdtBalance?.toFixed(2) || 0}
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <HStack
          sx={{
            '.chakra-stack__divider': {
              margin: 'auto var(--chakra-space-5)',
            },
          }}
          divider={
            <StackDivider display={{ base: 'none', sm: 'block' }} h="50px" />
          }
          justifyContent="space-between"
          flexWrap={{ base: 'wrap', sm: 'nowrap' }}
        >
          <Box textAlign="center">
            <Text
              variant="primary"
              fontWeight="600"
              fontSize={{ base: 16, md: 30 }}
            >
              Owned NFTs
            </Text>
            <Text fontSize={{ base: 20, md: 40 }}>
              {ownerStats?.ownedNftCount || 0}
            </Text>
          </Box>
          <Box textAlign="center">
            <Text
              variant="primary"
              fontWeight="600"
              fontSize={{ base: 16, md: 30 }}
            >
              Listed
            </Text>
            <Text fontSize={{ base: 20, md: 40 }}>
              {ownerStats?.listedNftCount || 0}
            </Text>
          </Box>
          <Box textAlign="center" w={{ base: '100%', sm: 'auto' }}>
            <Text
              variant="primary"
              fontWeight="600"
              fontSize={{ base: 16, md: 30 }}
            >
              Spent Amount
            </Text>
            <Text fontSize={{ base: 20, md: 40 }}>
              $ {userProfileData?.amountSpent || 0}
            </Text>
          </Box>
        </HStack>
        <Center mt="30px">
          <Button
            sx={{ 'svg path': { stroke: 'white' } }}
            leftIcon={<GrHistory />}
            variant="link"
            as={Link}
            to="/transactions"
          >
            View Transaction History
          </Button>
        </Center>
        <Image
          zIndex="1"
          position="absolute"
          h="100%"
          top={0}
          right={0}
          bottom={0}
          src={pattern}
          objectFit="cover"
          pointerEvents="none"
        />
      </Box>
      <Box
        bg="var(--chakra-colors-bgGradient) padding-box, var(--chakra-colors-primaryGradient) border-box"
        border="1px solid transparent"
        shadow="0px 4px 20px rgba(43, 45, 51, 0.16)"
        p="25px 30px"
        rounded="10px"
        maxW={{ lg: '313px' }}
        flex="1"
      >
        <Heading as="h4" fontSize={20}>
          My Referral Earnings
        </Heading>
        <Box
          rounded="8px"
          p="25px"
          my="10px"
          bg={`url(${CardBg}) no-repeat top right`}
          bgSize="cover"
        >
          <Flex mb="25px" justifyContent="space-between">
            <Box>
              <Text fontSize="14px" as="span">
                Earned
              </Text>
              <Text fontSize="20px" fontWeight="600">
                ${ownerStats?.referralReward?.toFixed(2)}
              </Text>
            </Box>
            <Box>
              <Text fontSize="14px" as="span">
                Withdrawable
              </Text>
              <Text fontSize="20px" fontWeight="600">
                ${ownerStats?.referralRewardToPay?.toFixed(2)}
              </Text>
            </Box>
          </Flex>
          <Button
            variant="primary"
            mx="auto"
            display="block"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={() => claimReferral(walletAddress)}
          >
            Withdraw Now
          </Button>
        </Box>
        <Text>Copy your personal link</Text>
        <InputGroup border="none" mt="10px">
          <Input
            value={`${window.location.href
              .split('/')
              .slice(0, 3)
              .join('/')}/signup?ref=${userProfileData?.ownerAddress}`}
            color="background"
            bg="white"
            focusBorderColor="none"
            readOnly
          />
          <InputRightAddon
            bg="background"
            border="none"
            cursor="pointer"
            // eslint-disable-next-line react/no-children-prop
            children={<BiCopy />}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.href
                  .split('/')
                  .slice(0, 3)
                  .join('/')}/signup?ref=${userProfileData?.ownerAddress}`,
              )

              showToast('success', 'Referral Copied', '')
            }}
          />
        </InputGroup>
      </Box>
    </Flex>
  )
}
