import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from 'routes'
import {
  ChakraProvider,
  createStandaloneToast,
  withDefaultColorScheme,
} from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import customTheme from 'utils/customTheme'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const theme = extendTheme(
  customTheme,
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
)

const queryClient = new QueryClient()

export const { toast } = createStandaloneToast()

export const EarnXContext = React.createContext({
  walletAddress: '',
  setWalletAddress: (value: string) => {
    console.log(value)
  },
})
export const App = () => {
  const [walletAddress, setWalletAddress] = React.useState('')

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <EarnXContext.Provider
            value={{
              walletAddress: walletAddress,
              setWalletAddress: setWalletAddress,
            }}
          >
            <AppRoutes />
            <ReactQueryDevtools initialIsOpen={false} />
          </EarnXContext.Provider>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  )
}
