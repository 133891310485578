export const menu = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Market',
    path: '/market',
  },
  {
    label: 'About Us',
    path: '/#about-us',
  },
  {
    label: 'How it works ?',
    path: '/#how-it-works',
  },
  {
    label: 'Investment Plan',
    path: '/#investment',
  },
  {
    label: 'Price and Analysis',
    path: '/#price-analytics',
  },
]

export const footerLinks = [
  { label: 'About us', url: '/#about-us' },
  { label: 'How it works', url: '/#how-it-works' },
  { label: 'invest', url: '/#investment' },
  { label: 'Price & Analysis', url: '/#price-analytics' },
  {
    label: 'FAQ',
    url: 'https://sites.google.com/view/earnx-faqs/home',
    isHref: true,
  },
]

export const securityLinks = [
  {
    label: 'Privacy Policy',
    url: 'https://sites.google.com/view/earnx-privacy-policy',
    isHref: true,
  },
]

export const socialLinks = [
  { url: 'https://twitter.com/', icon: 'twitter' },
  { url: 'https://facebook.com/', icon: 'fb' },
  { url: 'https://instagram.com/', icon: 'insta' },
]
