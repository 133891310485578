import React from 'react'
import logo from 'assets/images/logo.svg'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Flex,
  Text,
  Image,
  Button,
  IconButton,
  Avatar,
  Box,
} from '@chakra-ui/react'
import { menu } from 'utils/const'
import { Link, useNavigate } from 'react-router-dom'
import { useIsUserLogIn } from 'utils/hooks/index'
import { BiChevronDown } from 'react-icons/bi'
import { AiOutlineLogout } from 'react-icons/ai'
import { BsPerson } from 'react-icons/bs'
import { useGetUserData } from 'utils/apis/user.api'
import { disconnectWallet } from 'utils/bc-helper'
import { EarnXContext } from 'App'
import { EarnXText, MenuButtonIcon } from 'assets/icons'
import { scrollElementIntoView } from 'utils/helper'

export default function Header() {
  const navigate = useNavigate()
  const intoView = (id: string) => {
    const elem = document.getElementById(id)
    if (elem) scrollElementIntoView(elem, 'smooth')
  }
  const [isLargerThan960] = useMediaQuery('(min-width: 960px)')
  const isLogin = useIsUserLogIn()

  const { data: userProfileData } = useGetUserData()

  const { setWalletAddress } = React.useContext(EarnXContext)

  const handleLogout = async () => {
    localStorage.removeItem('token')
    await disconnectWallet()
    setWalletAddress('')
    navigate('/')
  }
  return (
    <Flex
      as="header"
      p="1.3rem"
      alignItems="center"
      boxShadow=" 0px 0px 15px 0px #0000001A"
      gap="25px"
      bg={isLargerThan960 ? 'transparent' : '#25213D'}
    >
      {isLargerThan960 ? (
        <>
          <Link to="/">
            <Image src={logo} />
          </Link>
          <Flex as="nav" mx="auto" gap="2" align="center">
            {menu.map(({ label, path }) => (
              <Text
                onClick={() => intoView(path)}
                as={Link}
                to={path}
                key={label}
                fontSize="14px"
                fontWeight="300"
                mr="1rem"
                whiteSpace="nowrap"
              >
                {label}
              </Text>
            ))}
          </Flex>
        </>
      ) : (
        <>
          <MobileMenu onClick={intoView} handleLogout={handleLogout} />
          <Box mx="auto">
            <EarnXText />
          </Box>
        </>
      )}
      {isLogin ? (
        <Flex alignItems="center" mr={{ md: '2%' }}>
          {isLargerThan960 && (
            <Text mr="15px">{userProfileData?.fullname}</Text>
          )}
          <Menu>
            <Link to="/profile">
              <Avatar src={userProfileData?.profilePicURL} />
            </Link>
            {isLargerThan960 && (
              <MenuButton p="5px" alignItems="center" alignContent="center">
                <BiChevronDown fontSize="24px" />
              </MenuButton>
            )}
            <MenuList>
              <MenuItem
                as={Link}
                to='/profile'
                icon={ <BsPerson fontSize="20px" />}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                icon={<AiOutlineLogout fontSize="20px" />}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <>
          <Button to="/login" variant="link" as={Link} w="90px">
            Login
          </Button>
          <Button to="/signup" variant="primary" as={Link} w="100px">
            Signup
          </Button>
        </>
      )}
    </Flex>
  )
}

function MobileMenu({
  onClick,
  handleLogout,
}: {
  onClick: (path: string) => void
  handleLogout: () => void
}) {
  const isLogin = useIsUserLogIn()

  return (
    <Menu variant="primary">
      <MenuButton
        as={IconButton}
        aria-label="Menu button"
        icon={<MenuButtonIcon />}
        variant="link"
      />
      <MenuList>
        {menu.map(({ label, path }) => (
          <MenuItem
            onClick={() => onClick(path)}
            as={Link}
            to={path}
            key={label}
            fontSize="14px"
            fontWeight="300"
            mr="1rem"
          >
            {label}
          </MenuItem>
        ))}
        {isLogin && (
          <MenuItem
            onClick={() => handleLogout()}
            icon={<AiOutlineLogout fontSize="20px" />}
          >
            Logout
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
