import React, { useState } from 'react'
import {
  Box,
  Heading,
  Highlight,
  Flex,
  Container,
  // Button,
  // ButtonGroup,
} from '@chakra-ui/react'
import Footer from 'components/footer'
import Header from 'components/header'
import MarketCard from 'components/marketCard'
import UserBg from 'assets/images/bg-2.png'
// import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { useGetMarketList } from 'utils/apis/nft.api'
import { NftType } from 'utils/types/nft.type'

export default function MarketPlace() {
  const [filter] = useState({
    price: false,
    sort: '',
  })
  const { data: marketNfts } = useGetMarketList(10, filter?.price, filter?.sort)
  // const handleFilter = (type: string) => {
  //   if (type === 'price') setFilter({ ...filter, price: true })
  //   if (type === 'sort') {
  //     if (filter?.sort === 'asc') setFilter({ ...filter, sort: 'des' })
  //     else setFilter({ ...filter, sort: 'asc' })
  //   }
  // }
  return (
    <>
      <Box
        bg={`url(${UserBg}) no-repeat top right`}
        bgSize="cover"
        pb="60px"
        position="relative"
        overflow="hidden"
      >
        <Header />
        <Heading
          as="h2"
          fontSize={{ base: '1.9em', md: '3.5em' }}
          textAlign="center"
          py="30px"
        >
          <Highlight
            query="Collectibles"
            styles={{
              bgGradient: 'var(--chakra-colors-primaryGradient)',
              bgClip: 'text',
            }}
          >
            Explore Collectibles
          </Highlight>
        </Heading>
        <Container maxW="1280px" mt={{ md: '60px' }}>
          {/* <ButtonGroup justifyContent="flex-end" display="flex" spacing="20px" mb="25px" ml="auto">
            <Button
              variant={filter?.price ? 'solid' : 'secondary'}
              onClick={() => handleFilter('price')}
            >
              Price
            </Button>
            <Button
              variant={filter?.sort ? 'solid' : 'secondary'}
              onClick={() => handleFilter('sort')}
              rightIcon={
                filter?.sort === 'asc' ? <BiChevronUp /> : <BiChevronDown />
              }
            >
              Sort By
            </Button>
          </ButtonGroup> */}
          <Flex wrap="wrap" gap="35px" justifyContent="center">
            {marketNfts?.pages?.map((page, index) =>
              page?.map((item: NftType) => (
                <MarketCard nftData={item} key={index} />
              )),
            )}
          </Flex>
        </Container>
        <Box
          position="absolute"
          bottom={10}
          right={0}
          transform="translate(50%, -100%)"
          bg="linear-gradient(104.03deg, #FE81AB 0%, #FDAA94 99.86%)"
          opacity="0.9"
          filter="blur(150px)"
          width={48}
          height={48}
        />
      </Box>
      <Footer />
    </>
  )
}
