import React from 'react'
import {
  Card,
  CardBody,
  Image,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'
import { useGetPriceChart } from 'utils/apis/nft.api'
export default function EarnXCard({ image }: { image: string }) {
  const { data: priceData, isLoading: isPriceChartLoading } = useGetPriceChart()

  return (
    <Card
      position="relative"
      _after={{
        content: `''`,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -150,
        right: 0,
        w: '520px',
        background: '#4F0D95',
        opacity: 0.4,
        filter: 'blur(80px)',
      }}
      maxW="425px"
    >
      <CardBody zIndex="1">
        <Image src={image} alt="Card Image" />
        <Text my="10px">Investment Bond</Text>
        <StatGroup justifyContent="space-between">
          <Stat variant="primary">
            <StatLabel>Owner</StatLabel>
            <StatNumber>EarnX</StatNumber>
          </Stat>

          <Stat variant="primary" flex="none">
            <StatLabel>Current Price</StatLabel>
            <StatNumber>
              {isPriceChartLoading
                ? 'Loading...'
                : `${priceData?.reason?.price} USDT`}
            </StatNumber>
          </Stat>
        </StatGroup>
      </CardBody>
    </Card>
  )
}
