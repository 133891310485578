import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import {
  buyNft,
  claimReferralReward,
  listNftForSale,
  mintNft,
} from 'utils/bc-helper'
import {
  NftType,
  ListNftParamType,
  BuyNftParamType,
  TransactionType,
  MintNFTParamType,
} from 'utils/types/nft.type'
import { PriceChartType } from 'utils/types/user.type'
import { client } from './api-client'
// &sortBy=Price&sortOrder=asc
export const useGetMarketList = (limit = 10, price?: boolean, sort?: string) =>
  useInfiniteQuery<NftType[], Error>(
    ['getallNFTs', limit, price, sort],
    ({ pageParam }) =>
      client(
        `nft/getAllMarketNfts?offset=${pageParam || 0}&limit=${limit}${
          price ? '&sortBy=Price' : ''
        }${sort ? `&sortOrder=${sort}` : ''}`,
      ),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length + 1
        console.log('next page', nextPage)
        return nextPage
      },
    },
  )
export const useGetTransaction = (limit = 10, price?: boolean, sort?: string) =>
  useInfiniteQuery<TransactionType[], Error>(
    ['getTransactions', limit, price, sort],
    ({ pageParam }) =>
      client(
        `nft/getTransactions?offset=${pageParam || 0}&limit=${limit}${
          price ? '&sortBy=Price' : ''
        }${sort ? `&sortOrder=${sort}` : ''}`,
      ),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length
        return nextPage
      },
    },
  )

export const useMintNft = () =>
  useMutation<unknown, Error, MintNFTParamType>(data => mintNft(data))

export const useListItemOnSale = () =>
  useMutation<any, Error, ListNftParamType>((data: ListNftParamType) =>
    listNftForSale(data?.publicKey, data?.tokenId, data?.isBuyBack),
  )

export const useBuyItem = () =>
  useMutation<any, Error, BuyNftParamType>((data: BuyNftParamType) =>
    buyNft(data?.publicKey, data?.tokenId),
  )

export const useClaimReferral = () =>
  useMutation<unknown, Error, string>(publicKey =>
    claimReferralReward(publicKey),
  )

export const useOwnerNft = (limit = 2, price?: boolean, sort?: string) =>
  useInfiniteQuery<NftType[], Error>(
    ['getUserNFTs', limit, price, sort],
    ({ pageParam }) =>
      client(
        `nft/getUserNfts?offset=${pageParam || 0}&limit=${limit}${
          price ? '&sortBy=Price' : ''
        }${sort ? `&sortOrder=${sort}` : ''}`,
      ),
    {
      getNextPageParam: (_, allPages) => {
        const nextPage = allPages.length + 1
        console.log('next page', nextPage)
        return nextPage
      },
    },
    // {
    //   enabled: useIsUserLogIn()
    // }
  )

export const useGetPriceChart = () =>
  useQuery<PriceChartType, Error>(['getPriceChart'], () =>
    client('market/getPriceChartData'),
  )
