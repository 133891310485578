import React, { useContext, useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CardBody,
  Flex,
  Heading,
  Highlight,
  HStack,
  Image,
  StackDivider,
  Text,
  Divider,
  List,
  ListItem,
  ListIcon,
  Center,
} from '@chakra-ui/react'
import Header from 'components/header'
import HomeBg from 'assets/images/home-bg.png'
import EarnXCard from 'components/earnXCard'
import {
  CashIcon,
  GrowIcon,
  GrowthIcon,
  HandShakeIcon,
  InvestIcon,
  SecurityIcon,
} from 'assets/icons'
import NFTImg from 'assets/images/nft.png'
// import WorldMap from 'assets/images/world-map.png'
import IGSBg from 'assets/images/igs-bg.png'
import Footer from 'components/footer'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import MarketCard from 'components/marketCard'
import TestimonialsData from 'utils/data/testimonial.json'
import Slider from 'react-slick'
import CardImage from 'assets/images/card-img.png'
import { BiCheck } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'
import {
  useGetMarketList,
  useGetPriceChart,
  useMintNft,
} from 'utils/apis/nft.api'
import { EarnXContext } from 'App'
import { useGetUserData } from 'utils/apis/user.api'
import { NftType } from 'utils/types/nft.type'
import MarketCard from 'components/marketCard'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const options: Highcharts.Options = {
  chart: {
    backgroundColor: 'transparent',
  },
  title: {
    text: '',
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#fff',
      },
      format: '${value}',
    },
    gridLineWidth: 0,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    area: {
      color: '#ffffff',
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'rgba(255, 255, 255, 0.3)'],
          [1, 'rgba(255, 255, 255, 0)'],
        ],
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
  series: [
    {
      showInLegend: false,
      type: 'area',
      color: '#fff',
      data: [50, 127, 167, 559, 755, 855, 955],
    },
  ],
}

export default function Landing() {
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const navigate = useNavigate()

  const { data: marketNfts } = useGetMarketList(6)
  const { data: priceData, isLoading: isPriceChartLoading } = useGetPriceChart()

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next)
      console.log(current, next)
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 908,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const { walletAddress } = useContext(EarnXContext)

  const { data: userProfileData } = useGetUserData()

  const { mutate: mintNft, isLoading } = useMintNft()

  useEffect(() => {
    const element = document.getElementById(
      `/#${window.location.href?.split('#')?.[1]}`,
    )
    element?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  return (
    <Box
      minH="100vh"
      bg={`url(${HomeBg}) no-repeat center top`}
      bgSize="cover"
      overflow="hidden"
    >
      <Header />
      <Container maxW="1280px">
        <Flex
          as="section"
          pt={{ base: '50px', md: '100px' }}
          alignItems="center"
          justify="space-between"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box textAlign={{ base: 'center', md: 'left' }}>
            <Heading fontSize={{ base: '40px', lg: '68px' }} maxW="600px">
              <Highlight
                query={['Future', 'NFTS']}
                styles={{ color: 'primary.500' }}
              >
                Invest in the Future of NFTS with earnx
              </Highlight>
            </Heading>
            <Button
              mt="15px"
              size="lg"
              variant="primary"
              onClick={() => navigate('/market')}
            >
              Explore Now
            </Button>
          </Box>
          <Box mt={{ base: '30px', md: 0 }}>
            <EarnXCard image={CardImage} />
          </Box>
        </Flex>
      </Container>
      <Box
        as="section"
        my={{ base: '40px', md: '120px' }}
        bg="secondaryGradient"
        py="35px"
      >
        <Container maxW="1280px">
          <HStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            textAlign="center"
            flexDirection={{ base: 'column', md: 'row' }}
            gap={{ base: '40px', md: 0 }}
            py={{ base: '35px', md: 0 }}
          >
            <Box flex="1">
              <HandShakeIcon />
              <Text variant="primary" my="8px">
                Customer{' '}
              </Text>
              <Text fontSize="28px" fontWeight="700">
                Reliability
              </Text>
            </Box>
            <Box flex="1">
              <SecurityIcon />
              <Text variant="primary" my="8px">
                Safe & Secure
              </Text>
              <Text fontSize="28px" fontWeight="700">
                Investments
              </Text>
            </Box>
            <Box flex="1">
              <GrowthIcon />
              <Text variant="primary" my="8px">
                Growth{' '}
              </Text>
              <Text fontSize="28px" fontWeight="700">
                100 M+
              </Text>
            </Box>
          </HStack>
        </Container>
      </Box>
      <Container maxW="1280px">
        <Flex
          as="section"
          pb="40px"
          id="/#about-us"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Text variant="icon">About Us</Text>
            <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
              <Highlight
                query="EarnX"
                styles={{
                  bgGradient: 'var(--chakra-colors-primaryGradient)',
                  bgClip: 'text',
                }}
              >
                What is EarnX
              </Highlight>
            </Heading>
            <Text variant="primary" maxW="540px">
              Welcome to EarnX. While most NFT projects hope to increase in
              value through popularity, EarnX NFT derives its value from actual
              assets chosen by savvy and experienced analysts. EarnX is the most
              solvent NFT project ever introduced, allowing any holder to cash
              out at any time.
            </Text>
          </Box>
          <Box>
            <Image src={NFTImg} alt="NFT Img" />
          </Box>
        </Flex>
        {/* <Box as="section" bg={`url(${WorldMap}) no-repeat center`} py="220px">
          <Box maxW="715px" mx="auto" textAlign="center">
            <Heading>
              <Highlight
                query={['EarnX', '6+ million', '175+']}
                styles={{
                  bgGradient: 'var(--chakra-colors-primaryGradient)',
                  bgClip: 'text',
                }}
              >
                EarnX is trusted by 6+ million users in 175+ countries
              </Highlight>
            </Heading>
            <Text my="15px">
              Lorem ipsum dolor sit amet consectetur. Sed sapien amet ultrices
              velit arcu amet. Tortor volutpat scelerisque hendrerit lectus
              tortor facilisis mauris. Nisi augue.
            </Text>
          </Box>
        </Box> */}
        <Box
          as="section"
          mb={{ base: '40px', md: '140px' }}
          id="/#how-it-works"
        >
          <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
            <Highlight
              query="works"
              styles={{
                bgGradient: 'var(--chakra-colors-primaryGradient)',
                bgClip: 'text',
              }}
            >
              How it works
            </Highlight>
          </Heading>
          <Flex gap="30px" mt="56px" justifyContent="center" flexWrap="wrap">
            <HowItWorksCard
              icon={<InvestIcon />}
              title="Invest in NFTs"
              info="Simply buy EarnX NFTs, which signify holding a certain
                  percentage of the EarnX portfolio."
            />
            <HowItWorksCard
              icon={<GrowIcon />}
              title="Watch your investment grow"
              info="You will be able to timely track the increase in the price
              worth of your NFT holdings from the ongoing report feeds."
            />
            <HowItWorksCard
              icon={<CashIcon />}
              title="Cash	out	at	any
              time	you	wish"
              info="When	your	EarnX	NFTs	reach	your	price	goal,	you	can	simply	place	a	selling	order	to	pay	out."
            />
          </Flex>
        </Box>
        <Box
          as="section"
          pb={{ base: '40px', md: '140px' }}
          textAlign="center"
          id="/#investment"
          position="relative"
          zIndex="1"
        >
          <Text variant="icon" fontSize={{ base: '1.9em', md: '3.5em' }}>
            <Highlight
              query="Plan"
              styles={{
                bgGradient: 'var(--chakra-colors-primaryGradient)',
                bgClip: 'text',
              }}
            >
              Investment Plan
            </Highlight>
          </Text>
          <Text
            fontSize={{ md: '30px' }}
            maxW="1000px"
            mx="auto"
            mt="20px"
            mb="65px"
          >
            <Highlight
              query={['EarnX', '10,000 NFT']}
              styles={{
                bgGradient: 'var(--chakra-colors-primaryGradient)',
                bgClip: 'text',
              }}
            >
              EarnX is unique NFT collection that is backed by actual assets.
              The total supply is 10,000 NFT.
            </Highlight>
          </Text>
          <Card maxW="335px" overflow="hidden" mx="auto">
            <CardBody>
              <Box bg="#281D52" p="15px 20px" rounded="22px">
                <Text as="span">Plan</Text>
                <Text
                  bgGradient="var(--chakra-colors-primaryGradient)"
                  bgClip="text"
                  fontSize="55px"
                  my="15px"
                >
                  {isPriceChartLoading
                    ? 'Loading...'
                    : `${priceData?.reason?.price}`}
                  $
                </Text>
                <Text>One time investment</Text>
                <Divider my="19px" borderColor="#2A3465" />
                <Text textAlign="left">Benefit:</Text>
                <List
                  spacing={3}
                  mt="10px"
                  textAlign="left"
                  color="white"
                  fontSize="14px"
                  fontWeight="300"
                >
                  <ListItem>
                    <ListIcon as={BiCheck} color="primary.500" />
                    Earn more rewards
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiCheck} color="primary.500" />
                    Be able to resale with higher price
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiCheck} color="primary.500" />
                    Trade or exchange with others
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiCheck} color="primary.500" />
                    Priority customer service
                  </ListItem>
                </List>
              </Box>
            </CardBody>
            <Button
              size="lg"
              variant="primary"
              onClick={() =>
                mintNft({
                  publicKey: walletAddress,
                  referralAddress:
                    userProfileData?.referallAddress ||
                    '0x0000000000000000000000000000000000000000',
                })
              }
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Buy Now
            </Button>
          </Card>
        </Box>
      </Container>
      <Box as="section" bg={`url(${IGSBg}) no-repeat center`} bgSize="cover">
        <Container maxW="1280px">
          <Flex p="118px 0 160px" direction={{ base: 'column', lg: 'row' }}>
            <Box maxW="591px" w="100%">
              <Text variant="icon">Reliable Company</Text>
              <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
                <Highlight
                  query={['make you grow']}
                  styles={{
                    bgGradient: 'var(--chakra-colors-primaryGradient)',
                    bgClip: 'text',
                  }}
                >
                  Investments that will make you grow
                </Highlight>
              </Heading>
            </Box>
            <Text variant="primary" flex="1" mt={{ base: '25px', lg: '0' }}>
              EarnX is a unique NFT collection that represents shares of our
              CRYPTO. Each NFT piece equals one share, and the total supply of
              shares is 10,000. As an investor, you can own a portion of our
              stock by purchasing NFT pieces. For example, if you hold 100 NFT
              pieces, you own 1% of the stock. This gives you the flexibility to
              sell your shares anytime, anywhere.
            </Text>
          </Flex>
        </Container>
      </Box>
      <HStack
        divider={
          <StackDivider
            display={{ base: 'none', lg: 'block' }}
            borderColor="gray.200"
          />
        }
        spacing={4}
        textAlign="center"
        mt="-90px"
        mb="40px"
        maxW="1200px"
        mx={{ base: '15px', lg: 'auto' }}
        rounded="16px"
        p={{ base: '25px 20px', lg: '35px 60px' }}
        bg="radial-gradient(151.95% 463.58% at -44.29% -62.34%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%) "
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        gap={{ base: '20px', lg: 0 }}
      >
        <ReliableStats label="Capital Raised" value="3.5M USD " />
        <ReliableStats label="NFTs Bond" value="10k+" />
        <ReliableStats label="Margin Ratio" value="UPTO 5%" />
        <ReliableStats label="Years" value="20+" />
      </HStack>
      <Container maxW="1280px">
        <Box
          as="section"
          my={{ base: '50px', md: '120px' }}
          id="/#price-analytics"
        >
          <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
            <Highlight
              query="Analysis"
              styles={{
                bgGradient: 'var(--chakra-colors-primaryGradient)',
                bgClip: 'text',
              }}
            >
              Price and Analysis
            </Highlight>
          </Heading>
          <Box
            my={{ base: '25px', md: '50px' }}
            bg="linear-gradient(17.42deg, #362A65 8.05%, rgba(42, 52, 101, 0.3) 98.51%)"
            p="40px 0"
            rounded="23px"
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                xAxis: {
                  categories: priceData?.data?.map(
                    item => `${item?.day}/${item?.month}/${item?.year}`,
                  ),
                  type: 'datetime',
                },
                series: [
                  {
                    name: 'Price',
                    type: 'area',
                    data: priceData?.data?.map(item => item?.price),
                  },
                ],
              }}
            />
          </Box>
          <Text variant="primary">
            {!isPriceChartLoading ? priceData?.reason?.reason : 'Loading...'}
          </Text>
        </Box>
        <Box as="section" my={{ base: '50px', md: '120px' }}>
          <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
            Marketplace
          </Heading>
          <Flex wrap="wrap" justifyContent="center" mt="50px" gap="24px">
            {marketNfts?.pages?.map((page, index) =>
              page?.map((item: NftType) => (
                <MarketCard nftData={item} key={index} />
              )),
            )}
          </Flex>
          <Center>
            <Button
              as={Link}
              to="/market"
              mt="30px"
              size="lg"
              variant="secondary"
            >
              View more
            </Button>
          </Center>
        </Box>
        <Flex
          as="section"
          mb={{ base: '40px', md: '100px' }}
          direction={{ base: 'column', xl: 'row' }}
        >
          <Box maxW={{ xl: '420px' }} w="100%">
            <Text variant="icon">Testimonials</Text>
            <Heading as="h2" fontSize={{ base: '1.9em', md: '3.5em' }}>
              <Highlight
                query="Clients Say"
                styles={{
                  bgGradient: 'var(--chakra-colors-primaryGradient)',
                  bgClip: 'text',
                }}
              >
                What Our Clients Say
              </Highlight>
            </Heading>
            <Text variant="primary" flex="1" mt="23px">
              Join the future of crypto investing with EarnX, and take advantage
              of our {`team's`} expertise to maximise returns on your
              investment. Whether you are an experienced investor or new to the
              world of crypto, our NFT reserve structure provides a secure and
              straightforward way to invest in the crypto market..
            </Text>
          </Box>
          <Box
            maxW="860px"
            w="100%"
            mt={{ base: '50px', xl: 0 }}
            mx={{ base: 'auto', lg: '0' }}
            minH="450px"
          >
            <Slider {...settings}>
              {TestimonialsData?.map((Testimonial, index) => (
                <Box
                  transition="all 0.3s"
                  key={Testimonial?.name}
                  // h={currentSlide === index ? '' : '250px'}
                  p="0 15px"
                >
                  <Box
                    bg="background"
                    transition="all 0.3s"
                    p={currentSlide === index ? '50px 35px' : '40px 35px 20px'}
                    rounded="16px"
                  >
                    <Flex gap="15px">
                      <Avatar rounded="16px" h="101px" w="101px" />
                      <Box>
                        <Text
                          as="h6"
                          display="block"
                          className="fs--20px fw--700"
                        >
                          {Testimonial?.name}
                        </Text>
                        <Text variant="primary" my="8px">
                          Investor
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                      variant="primary"
                      my="25px"
                      maxW="650px"
                      noOfLines={currentSlide === index ? undefined : 3}
                    >
                      {Testimonial?.message}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Flex>
      </Container>
      <Footer />
    </Box>
  )
}

interface ArrowType {
  className?: string
  style?: Record<string | number | symbol, undefined>
  onClick?: () => void
}
function PrevArrow(props: ArrowType) {
  const { className, style, onClick } = props
  return (
    <Center
      className={className}
      sx={{ svg: { w: '30px', h: '30px' } }}
      style={{
        ...style,
        color: '#fff',
        width: '60px',
        height: '60px',
        background: '#281D52',
        boxShadow: '0px 0px 4px rgba(147, 78, 206, 0.2)',
        borderRadius: '50%',
        left: '-10px',
        zIndex: 1,
        display: 'flex',
      }}
      onClick={onClick}
    >
      <IoIosArrowBack />
    </Center>
  )
}

function NextArrow(props: ArrowType) {
  const { className, style, onClick } = props
  return (
    <Center
      className={className}
      sx={{ svg: { w: '30px', h: '30px' } }}
      style={{
        ...style,
        color: '#fff',
        width: '60px',
        height: '60px',
        background: '#281D52',
        boxShadow: '0px 0px 4px rgba(147, 78, 206, 0.2)',
        borderRadius: '50%',
        right: '-10px',
        zIndex: 1,
        display: 'flex',
      }}
      onClick={onClick}
    >
      <IoIosArrowForward />
    </Center>
  )
}

function HowItWorksCard({
  title,
  info,
  icon,
}: {
  title: string
  info: string
  icon: JSX.Element
}) {
  return (
    <Card
      textAlign="center"
      flex="1"
      p="32px 35px 50px"
      variant="secondary"
      minW="320px"
      maxW="430px"
    >
      <CardBody>
        {icon}
        <Heading m="15px 0 36px" as="h5" fontSize="24px">
          {title}
        </Heading>
        <Text variant="primary">{info}</Text>
      </CardBody>
    </Card>
  )
}

function ReliableStats({ label, value }: { label: string; value: string }) {
  return (
    <Box flex={{ base: 'calc(50% - 10px)', lg: '100%' }}>
      <Text
        variant="primary"
        fontWeight="500"
        fontSize={{ base: '16px', md: '20px' }}
        my="8px"
      >
        {label}
      </Text>
      <Text fontSize={{ base: '20px', md: '40px' }} fontWeight="700">
        {value}
      </Text>
    </Box>
  )
}
