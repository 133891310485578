import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Image,
  Slide,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { NftType } from 'utils/types/nft.type'
import {
  useBuyItem,
  useGetPriceChart,
  useListItemOnSale,
} from 'utils/apis/nft.api'
import { EarnXContext } from 'App'
import { MarketSellIcon, PlatformSell } from 'assets/icons'
import { MdNavigateNext } from 'react-icons/md'
export interface MarketCardType {
  nftData: NftType
  isOwnedNft?: boolean
}

export default function MarketCard({
  nftData,
  isOwnedNft = false,
}: MarketCardType) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isResellOpen,
    onClose: onCloseResell,
    onOpen: onResellModalOpen,
  } = useDisclosure()
  const { mutate: buyItem, isLoading } = useBuyItem()
  const { walletAddress } = React.useContext(EarnXContext)

  const { data: priceData } = useGetPriceChart()

  return (
    <Card
      position="relative"
      variant="primary"
      maxW="425px"
      minW="280px"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <CardBody>
        <Flex position="relative" rounded="24px" overflow="hidden">
          <Image
            maxW="336px"
            src={
              nftData?.imageUrl ||
              'https://api.earnxnft.io/media-upload/mediaFiles/earnx/1.png'
            }
            alt="Card Image"
          />
          <Slide
            direction="bottom"
            style={{ position: 'absolute', maxWidth: '100%', height: '100%' }}
            in={isOpen}
          >
            <Center
              h="100%"
              bg="radial-gradient(151.95% 463.58% at -44.29% -62.34%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%) "
            >
              {isOwnedNft && !nftData?.isOnSale ? (
                <Button size="lg" w="160px" onClick={onResellModalOpen}>
                  Resell
                </Button>
              ) : isOwnedNft && nftData?.isOnSale ? (
                <Button size="lg" w="160px">
                  Listed
                </Button>
              ) : (
                <Button
                  size="lg"
                  w="160px"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onClick={() =>
                    buyItem({
                      publicKey: walletAddress,
                      tokenId: nftData?.tokenId,
                    })
                  }
                >
                  Buy
                </Button>
              )}
            </Center>
          </Slide>
        </Flex>
        <Flex my="10px" justifyContent="space-between">
          <Text my="10px">EarnX </Text>
          <Text>#{nftData?.tokenId}</Text>
        </Flex>
        <StatGroup justifyContent="space-between">
          <Flex gap="10px">
            <Avatar rounded="16px" h="54px" w="54px" />
            <Stat variant="primary">
              <StatLabel>Owner</StatLabel>
              <StatNumber fontSize="18px">{nftData?.user?.fullname}</StatNumber>
            </Stat>
          </Flex>

          <Stat variant="primary" flex="none">
            <StatLabel>Current Bid</StatLabel>
            <StatNumber fontSize="18px">
              $
              {nftData?.isOnSale || nftData?.isBuyBack
                ? nftData?.price
                : priceData?.reason?.price}{' '}
              USDT
            </StatNumber>
          </Stat>
        </StatGroup>
      </CardBody>
      <ResellModal
        isOpen={isResellOpen}
        onClose={onCloseResell}
        tokenId={nftData?.tokenId}
      />
    </Card>
  )
}

function ResellModal({
  isOpen,
  onClose,
  tokenId,
}: {
  isOpen: boolean
  onClose: () => void
  tokenId: number
}) {
  const { walletAddress } = React.useContext(EarnXContext)

  const { mutate: listNftOnSale, isLoading } = useListItemOnSale()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt="30px">Resell NFT</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="30px">
          <Flex
            bg="blue"
            p="13px 19px"
            gap="25px"
            mb="20px"
            rounded="12px"
            align="center"
            cursor="pointer"
            onClick={() => {
              listNftOnSale({
                publicKey: walletAddress,
                tokenId: tokenId,
                isBuyBack: false,
              })
              onClose()
            }}
            pointerEvents={isLoading ? 'none' : 'all'}
          >
            <MarketSellIcon />{' '}
            <Text flex="1" fontSize="20px" fontWeight="400">
              To Marketplace
            </Text>{' '}
            <MdNavigateNext color="#F57598" fontSize="25px" />
          </Flex>
          <Flex
            bg="blue"
            p="15px 22px"
            gap="25px"
            rounded="12px"
            align="center"
            cursor="pointer"
            onClick={() => {
              listNftOnSale({
                publicKey: walletAddress,
                tokenId: tokenId,
                isBuyBack: true,
              })
              onClose()
            }}
            pointerEvents={isLoading ? 'none' : 'all'}
          >
            <PlatformSell />{' '}
            <Text flex="1" fontSize="20px" fontWeight="400">
              To Platform
            </Text>{' '}
            <MdNavigateNext fontSize="25px" color="#F57598" />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
