/* eslint-disable @typescript-eslint/no-explicit-any */
import EarnX from 'utils/contracts/earnX.json'
import USDT from 'utils/contracts/usdt.json'
import EarnXMarketplace from 'utils/contracts/earnXMarketplace.json'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { KEYS } from 'keys/keys'
import { QueryClient } from '@tanstack/query-core'
import { BalanceType } from './types/user.type'
const EARNX_ADDRESS = '0x8245a7243e7105828eF66eFded9463B06dA95838'
const USDT_ADDRESS = '0x6e07eE8563357C25e3218497FAc536079220c3B4'
const EARNX_MARKETPLACE = '0xB02E1b45Fc1DE6b02F20e181BC6Df151Ed37d204'
import Web3 from 'web3'
import { Contract } from 'web3-eth-contract'
import { showToast } from './helper'
import { EarnXMarketplaceItemType, MintNFTParamType } from './types/nft.type'

const RPC = 'https://data-seed-prebsc-2-s1.binance.org:8545/'
const chainId = '0x61'
const networkName = 'BSC Testnet'
const nativeCurrencyName = 'Test BNB'
const nativeCurrencySymbol = 'tBNB'
const decimals = 18
const scanUrl = 'https://testnet.bscscan.com/'

let web3 = new Web3(RPC)

export const addBSCNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: chainId,
          rpcUrls: [RPC],
          chainName: networkName,
          nativeCurrency: {
            name: nativeCurrencyName,
            symbol: nativeCurrencySymbol,
            decimals: decimals,
          },
          blockExplorerUrls: [scanUrl],
        },
      ],
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const connectAccount = async (client: QueryClient) => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: 'https://bridge.walletconnect.org',
          chainid: 97,
          rpc: {
            97: RPC,
          },
        },
      },
    }
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    })

    console.log('web3Modal instance is', web3Modal)
    const provider = await web3Modal.connect()
    web3 = new Web3(provider)
    console.log('Web3 instance is', web3, provider)
    const chainid = await web3.eth.getChainId()
    console.log(chainid)
    if (chainid != 97) {
      await web3Modal.clearCachedProvider()
      window.localStorage.removeItem('walletconnect')
      window.localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER')
      showToast(
        'error',
        'Wrong network connected',
        'Please connect with BSC Testnet!',
      )
      await addBSCNetwork()
      return
    }
    const accounts = await web3.eth.getAccounts()
    console.log(accounts)
    client.setQueryData([KEYS.WALLET_ADDRESS], accounts[0])
    console.log('provider', await provider, await provider.connected)
    if (accounts[0]) console.log('success', 'wallet connected successfully!')
    provider.on('accountsChanged', (accounts: any) => {
      console.log('accountsChanged', accounts)
      client.setQueryData([KEYS.WALLET_ADDRESS], accounts[0])
    })

    return accounts[0]
  } catch (error) {
    console.log({
      isOpen: false,
      message: '',
    })
    throw error
  }
}

export const disconnectWallet = async () => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: 'https://bridge.walletconnect.org',
          chainid: 97,
          rpc: {
            97: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
          },
        },
      },
    }
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    })

    await web3Modal.clearCachedProvider()
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getEarnXContract = () => {
  const contract: Contract = new web3.eth.Contract(
    EarnX['abi'] as any,
    EARNX_ADDRESS,
  )

  return contract
}

export const getUsdtContract = () => {
  try {
    const contract = new web3.eth.Contract(USDT['abi'] as any, USDT_ADDRESS)
    console.log('contract', contract)
    return contract
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getEarnxMarketplaceContract = () => {
  try {
    const contract = new web3.eth.Contract(
      EarnXMarketplace['abi'] as any,
      EARNX_MARKETPLACE,
    )
    console.log('contract', contract)
    return contract
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getUsdtBalance = async (
  walletAddress: string,
): Promise<BalanceType> => {
  try {
    if (walletAddress && web3) {
      // const usdtContract = getUsdtContract();

      console.log('walletAddress', walletAddress)
      // const balanceInWei = await usdtContract.methods.totalSupply().call();
      const balanceInWei = await web3.eth.getBalance(walletAddress)
      console.log('balanceInWei', balanceInWei)
      const balance = parseFloat(web3.utils.fromWei(balanceInWei, 'ether'))

      return {
        balanceInWei: balanceInWei,
        balance: balance,
      }
    } else {
      return {
        balanceInWei: '0',
        balance: 0,
      }
    }
  } catch (err: any) {
    console.log(err?.message)
    throw err
  }
}

export const mintNft = async (params: MintNFTParamType) => {
  try {
    let referralAddress = '0x0000000000000000000000000000000000000000'
    const publicKey: string = params?.publicKey
    referralAddress = params?.referralAddress

    if (!publicKey) {
      showToast(
        'error',
        'Please connect your wallet first',
        'You need to connect your wallet before minting',
      )
    }

    const usdtContract: Contract = getUsdtContract()

    const earnXContract: Contract = getEarnXContract()
    console.log('publicKey', publicKey)
    const priceInWei = await earnXContract.methods.price().call()

    const approveTrx = await usdtContract.methods
      .approve(EARNX_ADDRESS, priceInWei)
      .send({
        from: publicKey,
      })

    console.log('approveTrx', approveTrx)

    const trx = await earnXContract.methods.mint(referralAddress).send({
      from: publicKey,
    })

    console.log('trx', trx)

    showToast(
      'success',
      'NFT Minted Successfully',
      'You have successfully minted an NFT',
    )

    return trx
  } catch (err: any) {
    console.log(err)
    showToast('error', 'Something went wrong', err?.message)
    throw err
  }
}

export const listNftForSale = async (
  publicKey: string,
  tokenId: number,
  isBuyBack: boolean,
) => {
  try {
    if (!publicKey) {
      showToast(
        'error',
        'Please connect your wallet first',
        'You need to connect your wallet before minting',
      )
    }

    const earnXContract = getEarnXContract()
    const earnXMarketplace = getEarnxMarketplaceContract()

    const price = await earnXContract.methods.price().call()

    const isApprovedForAll = await earnXContract.methods
      .isApprovedForAll(publicKey, EARNX_MARKETPLACE)
      .call()

    if (!isApprovedForAll) {
      const approveTrx = await earnXContract.methods
        .setApprovalForAll(EARNX_MARKETPLACE, true)
        .send({
          from: publicKey,
        })

      console.log('approveTrx', approveTrx)
    }

    const trx = await earnXMarketplace.methods
      .listItemOnSale(tokenId, isBuyBack, price)
      .send({
        from: publicKey,
      })

    console.log('trx', trx)

    showToast(
      'success',
      'NFT Listed Successfully',
      'You have successfully listed an NFT',
    )
  } catch (err: any) {
    console.log(err)
    showToast('error', 'Something went wrong', err?.message)
    throw err
  }
}

export const buyNft = async (publicKey: string, tokenId: number) => {
  try {
    if (!publicKey) {
      showToast(
        'error',
        'Please connect your wallet first',
        'You need to connect your wallet before minting',
      )
      return
    }

    const usdtContract: Contract = getUsdtContract()

    const earnXMarketplaceContract: Contract = getEarnxMarketplaceContract()

    const itemId = await earnXMarketplaceContract.methods
      .tokenIdToItemId(tokenId)
      .call()

    if (itemId == 0) {
      showToast(
        'error',
        'Item is not on sale',
        'You cannot buy an item which is not on sale',
      )
      return
    }

    const itemData: EarnXMarketplaceItemType =
      await earnXMarketplaceContract.methods.earnXItems(itemId).call()

    const priceInWei = itemData?.price
    const approveTrx = await usdtContract.methods
      .approve(EARNX_MARKETPLACE, priceInWei)
      .send({
        from: publicKey,
      })

    console.log('approveTrx', approveTrx)

    const gas = await earnXMarketplaceContract.methods
      .buyItem(tokenId)
      .estimateGas({
        from: publicKey,
      })

    console.log(gas)

    const trx = await earnXMarketplaceContract.methods.buyItem(tokenId).send({
      from: publicKey,
    })

    console.log('trx', trx)

    showToast(
      'success',
      'NFT Bought Successfully',
      'You have successfully bought an NFT',
    )

    return trx
  } catch (err: any) {
    console.log(err)
    showToast('error', 'Something went wrong', err?.message)
    throw err
  }
}

export const claimReferralReward = async (publicKey: string) => {
  try {
    if (!publicKey) {
      showToast(
        'error',
        'Please connect your wallet first',
        'You need to connect your wallet before minting',
      )
      return
    }

    const earnX: Contract = getEarnXContract()

    const referralCount = await earnX.methods.referralCount(publicKey).call()

    if (referralCount < 150) {
      showToast('error', 'Cannot claim before 150 referrals', '')
      return
    }

    const amountInWei = await earnX.methods
      .referralRewardToPay(publicKey)
      .call()

    const trx = await earnX.methods.claimReferralReward(amountInWei).send({
      from: publicKey,
    })

    console.log('trx', trx)

    showToast(
      'success',
      'Referral Claimed successfully',
      'You have successfully claimed the referral',
    )

    return trx
  } catch (err: any) {
    console.log(err)
    showToast('error', 'Something went wrong', err?.message)
    throw err
  }
}
