import React from 'react'
import {
  Box,
  Container,
  Flex,
  Link as ExternalLink,
  Text,
} from '@chakra-ui/react'
import { footerLinks, securityLinks } from 'utils/const'
import { FooterLink } from 'utils/types/global'
import {
  FacebookIcon,
  FooterLogo,
  InstagramIcon,
  TwitterIcon,
} from 'assets/icons'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <Box as="footer" mt="90px" id="/#contact-us">
      <Container maxW="1280px">
        <Flex
          wrap="wrap"
          mt="2.2rem"
          pb="1.5rem"
          color="secondaryText"
          fontSize="14px"
          gap={{ base: '20px', md: '0' }}
        >
          <Box w={{ base: '100%', md: '30%' }}>
            <FooterLogo />
            <Text variant="primary" mt="15px">
              The world’s first and largest digital marketplace for crypto
              collectibles and non-fungible tokens (NFTs). Buy, sell, and
              discover exclusive digital items.
            </Text>
          </Box>
          <FooterLinks heading="Resources" item={footerLinks} />
          <FooterLinks heading="Security" item={securityLinks} />
          <Box w={{ base: '100%', sm: '48%', md: '30%' }}>
            <Text fontSize="20px">Contact Us</Text>
            <Flex gap="10px" mt="15px">
              <FacebookIcon />
              <TwitterIcon />
              <InstagramIcon />
            </Flex>
          </Box>
        </Flex>
        <Text fontSize="12.6" py="1.5rem">
          © Copyright {new Date().getFullYear()}, All Rights Reserved by EarnX
        </Text>
      </Container>
    </Box>
  )
}

type Props = {
  item: FooterLink[]
  heading: string
}

function FooterLinks({ item, heading }: Props) {
  const scrollView = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Box w={{ base: '100%', sm: '48%', md: '20%' }}>
      <Text fontSize="20px" mb="15px">
        {heading}
      </Text>
      {item.map(({ label, url, isHref }) =>
        isHref ? (
          <Text
            as={ExternalLink}
            variant="primary"
            href={url}
            isExternal
            display="block"
            mb="15px"
            key={label}
          >
            {label}
          </Text>
        ) : (
          <Text
            as={Link}
            onClick={() => scrollView(url)}
            variant="primary"
            to={url}
            display="block"
            mb="15px"
            key={label}
          >
            {label}
          </Text>
        ),
      )}
    </Box>
  )
}
