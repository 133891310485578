import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthFormValues, OwnerStatsType, User } from 'utils/types/user.type'
import { client } from './api-client'
import { SignupRes } from 'utils/types/user.type'
import { useIsUserLogIn } from '../hooks'
import { getUsdtBalance } from 'utils/bc-helper'
import { showToast } from 'utils/helper'

export const useLogin = () =>
  useMutation<
    SignupRes & { token: { access_token: string } },
    Error,
    { email: string; password: string }
  >(data =>
    client('auth/login', {
      data,
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useSignup = () =>
  useMutation<SignupRes, Error, AuthFormValues>(data =>
    client('auth/signup', {
      data,
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useVerifyEmail = () =>
  useMutation<
    SignupRes & { token: { access_token: string } },
    Error,
    { email: string; otp: string }
  >(data =>
    client('auth/verifyEmail', {
      data,
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useSendOtp = () =>
  useMutation<unknown, Error, string>(email =>
    client('auth/forgotPassword', {
      data: { email },
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useResendOtp = () =>
  useMutation<unknown, Error, string>(email =>
    client('auth/resendOtp', {
      data: { email },
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useVerifyOtp = () =>
  useMutation<
    { token: { access_token: string } },
    Error,
    { email: string; otp: string }
  >(data =>
    client('auth/verifyOtpForForgotPassword', {
      data,
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useResetPassword = (token: string) =>
  useMutation<unknown, Error, string>(password =>
    client('auth/resetPassword', {
      data: { password },
      headers: {
        Authorization: token,
      },
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useGetUserData = () =>
  useQuery<User, Error>(['userProfile'], () => client('auth/getLoggedInUser'), {
    enabled: useIsUserLogIn(),
  })

export const useSetOwner = () =>
  useMutation<unknown, Error, string>(ownerAddress =>
    client('auth/setOwnerAddress', {
      data: { ownerAddress },
    }).catch(err => {
      showToast('error', err?.message, '')
    }),
  )

export const useGetUserBalance = (walletAddress: string) =>
  useQuery(
    ['getUserBalance', walletAddress],
    () => getUsdtBalance(walletAddress),
    {
      enabled: useIsUserLogIn(),
    },
  )

export const useGetUserProfileStats = () =>
  useQuery<OwnerStatsType, Error>(
    ['getOwnerStats'],
    () => client('nft/getOwnerStats'),
    {
      enabled: useIsUserLogIn(),
    },
  )
