/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box } from '@chakra-ui/react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'
import { BiDownvote, BiUpvote } from 'react-icons/bi'

export type DataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  height?: string
}

export default function DataTable<Data extends object>({
  data,
  columns,
  height,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  return (
    <Box overflow="auto" h={height}>
      <Table>
        <Thead>
          {table
            .getHeaderGroups()
            .map(
              (headerGroup: {
                id: React.Key | null | undefined
                headers: any[]
              }) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const meta: any = header.column.columnDef.meta
                    return (
                      <Th
                        fontSize="16px"
                        fontWeight="500"
                        color="primaryText"
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        textTransform="capitalize"
                        filter="auto"
                        borderColor="#281D52"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <BiUpvote aria-label="sorted descending" />
                            ) : (
                              <BiDownvote aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    )
                  })}
                </Tr>
              ),
            )}
        </Thead>
        <Tbody>
          {table
            .getRowModel()
            .rows.map(
              (row: {
                id: React.Key | null | undefined
                getVisibleCells: () => any[]
              }) => (
                <Tr
                  key={row.id}
                  css={`
                    :last-child td {
                      border: none;
                    }
                  `}
                >
                  {row
                    .getVisibleCells()
                    .map(
                      (cell: {
                        column: { columnDef: { meta: any; cell: any } }
                        id: React.Key | null | undefined
                        getContext: () => any
                      }) => {
                        const meta: any = cell.column.columnDef.meta
                        return (
                          <Td
                            fontSize="16px"
                            style={{ textAlign: 'start' }}
                            key={cell.id}
                            isNumeric={meta?.isNumeric}
                            position="relative"
                            borderColor="#281D52"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        )
                      },
                    )}
                </Tr>
              ),
            )}
        </Tbody>
      </Table>
    </Box>
  )
}
