import React, { useEffect, useState } from 'react'
import {
  Grid,
  Heading,
  Box,
  Input,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Icon,
  Flex,
  PinInput,
  PinInputField,
  HStack,
  useToast,
} from '@chakra-ui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { BiHide, BiShow } from 'react-icons/bi'
import { AuthFormValues } from 'utils/types/user.type'
import EarnXCard from 'components/earnXCard'
import AuthBg from 'assets/images/auth-bg.jpg'
import {
  InstagramIcon,
  LogoIcon,
  TwitterIcon,
  FacebookIcon,
} from 'assets/icons'
import NFTImage from 'assets/images/nft-img.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  useResendOtp,
  useResetPassword,
  useSendOtp,
  useVerifyEmail,
  useVerifyOtp,
} from 'utils/apis/user.api'

export default function ForgotPassword() {
  const location = useLocation()
  const navigate = useNavigate()
  const isVerifyEmail = location?.state?.email
  const toast = useToast()
  const [otp, setOtp] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [otpVerified, setOtpVerified] = useState(false)
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const {
    mutate: verifyEmail,
    data: verifyEmailResp,
    isLoading: verifyLoading,
    reset: emailReset,
  } = useVerifyEmail()
  const {
    mutate: verifyOtp,
    data: verifyOtpResp,
    isLoading: verifyOtpLoading,
  } = useVerifyOtp()

  const {
    mutate: sendOtp,
    isLoading: sendingOtp,
    isSuccess: sendOtpSuccess,
    reset: sendReset,
  } = useSendOtp()

  const {
    mutate: resetPassword,
    isLoading: resetLoading,
    isSuccess: resetSuccess,
  } = useResetPassword(verifyOtpResp?.token?.access_token || '')

  const {
    mutate: resendOtp,
    reset: reSendReset,
    isSuccess: reSendSuccess,
  } = useResendOtp()

  useEffect(() => {
    if (reSendSuccess) {
      reSendReset()
      toast({
        status: 'success',
        description: 'Otp send successfully!',
        position: 'top',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [reSendSuccess])

  useEffect(() => {
    if (resetSuccess) {
      toast({
        status: 'success',
        description: 'Password reset successfully!',
        position: 'top',
        duration: 2000,
        isClosable: true,
      })
      navigate('/login')
    }
  }, [resetSuccess])

  useEffect(() => {
    if (verifyOtpResp) {
      setOtp(false)
      setOtpVerified(true)
      toast({
        status: 'success',
        description: 'Otp verified successfully!',
        position: 'top',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [verifyOtpResp])

  useEffect(() => {
    if (isVerifyEmail || sendOtpSuccess) setOtp(true)
    if (sendOtpSuccess) {
      sendReset()
      toast({
        status: 'success',
        description: 'Otp send successfully!',
        position: 'top',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [isVerifyEmail, sendOtpSuccess])

  useEffect(() => {
    if (verifyEmailResp?.token) {
      emailReset()
      toast({
        title: 'Email verified.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      localStorage.setItem(
        'token',
        verifyEmailResp?.token?.access_token?.split(' ')?.[1],
      )
      navigate('/profile')
    }
  }, [verifyEmailResp])

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<AuthFormValues>()

  const onSubmit: SubmitHandler<AuthFormValues> = values => {
    if (!otp && !otpVerified) {
      return sendOtp(values.email)
    }
    if (otp && isVerifyEmail) {
      return verifyEmail({ email: isVerifyEmail, otp: otpValue })
    }
    if (otp && !isVerifyEmail) {
      return verifyOtp({ email: values.email, otp: otpValue })
    }
    if (otpVerified) resetPassword(values?.password)
  }

  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      bg={`url(${AuthBg}) no-repeat center`}
      bgSize="cover"
      py="100px"
      position="relative"
    >
      <Grid justifyItems="center">
        <Box
          position="absolute"
          top="30px"
          left={{ base: '50%', md: '30px' }}
          transform={{ base: 'translate(-50%, 0)', md: 'none' }}
        >
          <Link to="/">
            <LogoIcon />
          </Link>
        </Box>
        <Box display={{ base: 'none', md: 'block' }}>
          <EarnXCard image={NFTImage} />
        </Box>
        <Flex
          position="absolute"
          bottom="30px"
          left={{ base: '50%', md: '30px' }}
          transform={{ base: 'translate(-50%, 0)', md: 'none' }}
          gap="30px"
        >
          <FacebookIcon /> <TwitterIcon /> <InstagramIcon />
        </Flex>
      </Grid>
      <Box px="15px">
        <Heading fontSize={{ base: '34px', md: '55px' }}>
          {isVerifyEmail
            ? 'Verify Email'
            : otp
            ? 'Verification code'
            : otpVerified
            ? 'Password Reset'
            : 'Forgot Password'}
        </Heading>
        <Text mt="15px">
          {otp
            ? `Please check your email, we've sent a code to ${
                isVerifyEmail || getValues('email')
              }`
            : otpVerified
            ? 'Enter your new password but make sure it’s not any old.'
            : 'Enter your associated email to get verification code.'}
        </Text>
        <Box
          mt="30px"
          w="100%"
          maxW="420px"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {otpVerified ? (
            <FormControl isInvalid={Boolean(errors.password)} mb="25px">
              <FormLabel htmlFor="password">New Password</FormLabel>
              <InputGroup size="md">
                <Input
                  size="lg"
                  variant="primary"
                  focusBorderColor="primary.500"
                  {...register('password', {
                    required: 'Password is required',
                    validate: {
                      isCharacter: value =>
                        // eslint-disable-next-line
                        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ||
                        'Must contain one Special character',
                      // eslint-disable-next-line
                      isSmall: value =>
                        /[a-z]/.test(value) || 'Must contain one Small letter',
                      isCapital: value =>
                        /[A-Z]/.test(value) ||
                        'Must contain one Capital character',
                      isDigit: value =>
                        /\d/.test(value) || 'Must contain one Digit character',
                    },
                    minLength: {
                      value: 8,
                      message: 'Minimum length should be 8',
                    },
                  })}
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  placeholder="Password"
                  autoComplete="new-password"
                />
                <InputRightElement
                  // eslint-disable-next-line react/no-children-prop
                  children={
                    <Icon
                      as={show ? BiShow : BiHide}
                      color="gray.400"
                      onClick={handleClick}
                    />
                  }
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
          ) : otp ? (
            <HStack>
              <PinInput
                size="lg"
                focusBorderColor="primary.500"
                otp
                onChange={value => setOtpValue(value)}
              >
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
                <PinInputField
                  borderColor="primary.500"
                  _focus={{ boxShadow: '0 0 0 3px #F57598' }}
                />
              </PinInput>
            </HStack>
          ) : (
            <FormControl isInvalid={Boolean(errors.email)} mb="25px">
              <FormLabel htmlFor="name">Email</FormLabel>
              <Input
                size="lg"
                _autofill={{ bg: 'none' }}
                focusBorderColor="primary.500"
                placeholder="john@mail.com"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Enter valid email.',
                  },
                })}
                variant="primary"
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          )}
          {otp && (
            <Text mt="15px">
              Didn’t get a code?{' '}
              <Button
                textDecor="underline"
                variant="link"
                onClick={() => resendOtp(isVerifyEmail || getValues('email'))}
              >
                Click to resend.
              </Button>
            </Text>
          )}
          <Button
            fontWeight="400"
            w="100%"
            my="35px"
            variant="primary"
            type="submit"
            size="lg"
            isLoading={
              verifyLoading || sendingOtp || verifyOtpLoading || resetLoading
            }
          >
            {otp
              ? isVerifyEmail
                ? 'Verify Email'
                : 'Reset Password'
              : otpVerified
              ? 'Reset Password'
              : 'Get Code'}
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}
