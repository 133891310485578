export default {
  fonts: {
    body: 'Space Grotesk',
    heading: 'Space Grotesk',
  },
  colors: {
    primary: {
      50: '#F57598',
      100: '#F57598',
      200: '#F57598',
      300: '#F57598',
      400: '#F57598',
      500: '#F57598',
      600: '#F57598',
      700: '#F57598',
      800: '#F57598',
      900: '#F57598',
    },
    primaryText: '#8390B2',
    background: '#362A65',
    pink: '#F57598',
    blue: '#281D52',
    bgGradient: 'linear-gradient(316.06deg, #171031 -1.94%, #281D52 100%)',
    primaryGradient: 'linear-gradient(104.03deg, #C12890 0%, #FDAA94 99.86%)',
    secondaryGradient:
      ' linear-gradient(85.88deg, rgba(54, 42, 101, 0.35) 32.89%, rgba(54, 42, 101, 0.47) 65.73%)',
  },
  components: {
    FormLabel: {
      baseStyle: { fontWeight: '400' },
    },
    Input: {
      baseStyle: {
        field: {
          fontWeight: '400',
          color: 'primaryText',
          bg: 'background',
        },
      },
    },
    Heading: {
      baseStyle: { color: 'white' },
    },
    Stat: {
      baseStyle: { container: { color: 'white' } },
      variants: {
        primary: {
          container: { color: 'white' },
          label: { color: 'primaryText' },
        },
      },
    },
    Text: {
      baseStyle: { color: 'white' },
      variants: {
        primary: { color: 'primaryText' },
        pink: { color: 'pink' },
        icon: {
          _before: {
            content: `""`,
            display: 'inline-block',
            width: '12px',
            height: '12px',
            bg: 'primaryGradient',
            mr: '0.4em',
          },
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          rounded: '26px',
          bg: 'background',
        },
      },
      variants: {
        primary: {
          container: {
            bg: '#362A65',
          },
        },
        secondary: {
          container: {
            border: 'none',
            bg: 'bgGradient',
            boxShadow:
              '60px 60px 120px #1A2A3A, inset -2px -2px 2px #202339, inset 2px 2px 2px #151E4A',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        border: '1px solid transparent',
        fontWeight: '500',
        color: 'white',
      },
      variants: {
        primary: {
          bg: 'var(--chakra-colors-primaryGradient) padding-box, var(--chakra-colors-primaryGradient) border-box',
          _hover: {
            bg: 'transparent',
            color: 'primary.500',
            borderColor: 'primary.500',
          },
        },
        secondary: {
          bg: ' rgba(79, 13, 149, 0.1)',
          border: '1px solid #4F0D95',
          color: 'white',
          _hover: {
            bg: '#4F0D95',
          },
        },
        link: {
          color: 'white',
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          border: 'none',
          bg: 'secondaryGradient',
        },
        item: {
          bg: 'transparent',
        },
      },
      variants: {
        primary: {
          list: {
            border: 'none',
            bg: 'background',
            zIndex: 2,
          },
          item: {
            bg: 'transparent',
            py: '10px',
            _hover: {
              bg: 'blue',
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'background',
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'bgGradient',
        color: 'white',
        fontWeight: '400',
      },
    },
  },
}
